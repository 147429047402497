import { Row, Col, Button, Tooltip } from "antd";

import { DownCircleFilled } from "@ant-design/icons";
import React, { useCallback, useEffect, useState } from "react";
import { getDropInformation } from "utils/drop-client";
const INITIAL_STATE = {
  name: "",
  version: "",
  author: "",
  author_homepage: "",
  requires_php: "",
  last_updated: "",
  slug: "",
  download_url: "",
  request_time_elapsed: "",
};
function Download() {
  const [stagingInfo, setStagingInfo] = useState(INITIAL_STATE);
  const [prodInfo, setProdInfo] = useState(INITIAL_STATE);

  const fetchData = useCallback(() => {
    getDropInformation(process.env.REACT_APP_DROP_STAGING_SERVER_VERSION_URL)
      .then((res) => {
        if (res && res.data) {
          setStagingInfo({ ...stagingInfo, ...res.data });
        }
      })
      .catch((err) => { });

    getDropInformation(process.env.REACT_APP_DROP_STAGING_SERVER_VERSION_URL)
      .then((res) => {
        if (res && res.data) {
          setProdInfo({ ...prodInfo, ...res.data });
        }
      })
      .catch((err) => { });
  }, [stagingInfo, prodInfo]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <div className="tabled">
        <Row gutter={[12, 3]} justify={"center"} align={'center'} style={{height: 'screen'}}>
          <Col xs={24} md={24} lg={12} xl={12} xxl={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "10%",
              }}
            >
              <Tooltip title="MoveOn Drop Plugin">
                <Button
                  type="primary"
                  icon={<DownCircleFilled />}
                  onClick={() => {
                    let url ="https://moveondrop.com/wp-content/uploads/2025/02/moveon-drop-wp.zip";
                    window.open(url, "_blank");
                  }}
                >
                  Download Wordpress Plugin
                </Button>
              </Tooltip>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Download;
