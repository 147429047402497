import React, {useState, useEffect, useCallback} from "react";
import { useHistory } from "react-router-dom";
import { customArrow } from "../Custom";
import {
  Layout,
  Select,
  Col,
  Row,
  Pagination,
  Button,
  Tooltip,
  Dropdown,
  Menu,
  Collapse,
  Checkbox,
    message
} from "antd";
import {
  Filters,
  Sort,
  ProductListDownload,
  FilterExpand,
  FilterShrink,
} from "assets/SvgIcons";
import { stores } from "../Stores";
import { store_rates } from "consts/products";
import {
  getStoreRates,
  fetchProducts,
  getProductDetails,
} from "utils/product-client";
import "../products.css"
import ListContainer from "./ListContainer";
import FormBlock from "./FormBlock";

const { Option } = Select;

function Products() {
  const [products, setProducts] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [_, setProductDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [priceInfo, setPriceInfo] = useState({});
  const [input, setInput] = useState({
    storeId: "10",
    keyword: "bags",
    errors: "",
    sort: {
      sortType: '',
      sortOrder: ''
    },
    imageUuid: '',
    page: null,
  });
  const [filtersList, setFiltersList] = useState([]);
  const [priceRange, setPriceRange] = useState(
    {
      key: "",
      separator: "",
      min: null,
      max: null
    })
  const [valuation, setValuation] = useState(
    {
      key: "",
      values: [],
      separator: ""
    }
  )
  const [attrValues, setAttrValues] = useState(
    {
      key: "",
      values: [],
      separator: ""
    }
  )

  const [visible, setVisible] = useState(false);
  const [sortLabel, setSortLabel] = useState("");
  const history = useHistory();

  const fetchInitialProducts = useCallback(()=> {
    setLoading(true);
    const response = fetchProducts(input);
    response
      .then((res) => {
        localStorage.setItem('product-filters', JSON.stringify(res?.data?.filters?.configurator))
        setPriceInfo(
          store_rates.find((item) => item.storeId === input.storeId)
        );
        setProducts(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  },[])

  useEffect(() => {
    fetchInitialProducts()
  }, [fetchInitialProducts])

  useEffect(()=> {
    const existingFilters = localStorage.getItem('product-filters')
    if (existingFilters === 'undefined' || !existingFilters) {
      return
    }
    const filtersObj = JSON.parse(localStorage.getItem('product-filters'))
    const priceRangeInput = (
      <div style={{ display: 'flex', gap: '4px', paddingTop: '16px', paddingBottom: '16px' }}>
        <input
          id="min"
          type="number"
          value={priceRange.min}
          onChange={
            (e) => {
              setPriceRange(pre => {
                return {
                  ...pre, min: e.target.value,
                  key: filtersObj.pr?.key,
                  separator: filtersObj.pr?.separator
                }
              })
            }
          }
          style={{ width: "90px" }}
          className="price-range-input"
        />
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <span>-</span>
        </div>
        <input
          id="max"
          type="number"
          value={priceRange.max}
          onChange={
            (e) => {
              setPriceRange(pre => {
                return {
                  ...pre,
                  max: e.target.value,
                  key: filtersObj.pr?.key,
                  separator: filtersObj.pr?.separator
                }
              })
            }
          }
          style={{ width: "90px" }}
          className="price-range-input"
        />
      </div>
    )

    setFiltersList((prevState) => {
      return [
        {
          key: -2,
          label: filtersObj.pr?.title,
          children:
            <div>{priceRangeInput}</div>
        },
        {
          key: -1,
          label: filtersObj.features?.title,
          children: <p>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '15px', marginTop: '26px' }}>
              {filtersObj.features?.values?.map((feature, index) => {
                return <li key={index}><Checkbox onChange={(e) => handleValuationFilter(e, feature)}><span className='edit-import-tooltip-list-text'>{feature.label}</span></Checkbox></li>
              })
              }
            </div>
          </p>
        },
        ...filtersObj.attr?.values?.map((attribute, index) => {
          let element = (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '15px', marginTop: '26px' }}>
              {
                attribute.values.map((elm, index) => {
                    return <li key={index}><Checkbox onChange={(e) => handleAttributeFilter(e, elm)}><span className='edit-import-tooltip-list-text'>{elm.label}</span></Checkbox></li>
                  }
                )
              }
            </div>
          )
          return {
            key: index,
            label: attribute.label,
            children: <p>{element}</p>
          }
        })]
    })

  },[loading])


  const detailsPage = (link) => {
    setLoading(true);
    const response = getProductDetails(link);
    response
      .then((res) => {
        if (res?.status === 200) {
          setProductDetails(res.data);
          history.push({
            pathname: `/productDetails/product?url=${encodeURIComponent(link)}`,
            state: res?.data?.data,
          });
          setLoading(false);
          setInput({
            storeId: "10",
            keyword: "bags",
            errors: "",
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err?.response?.status === 422) {
          setInput({ ...input, errors: err?.response?.data?.message });
        }
      });
  };

  const handleStoreChange = (value) => {
    setInput({ ...input, storeId: value });
  };

  useEffect(() => {
    const response = getStoreRates();
    response
      .then((res) => {
        if (res?.status === 200) {
          const total = res?.data?.data;
          const filteredArray = total.map((item) => {
            return {
              totalFx: item.totalFx,
              storeId: item.storeId,
              min_order_amount: item.min_order_amount,
            };
          });
          localStorage.setItem("store_rates", JSON.stringify(filteredArray));
        }
      }).catch(({ response }) => {
          if (response?.data) {
            messageApi.open({
              type: 'error',
              content: response?.data?.message,
              style: {marginTop: '50px'}
            })
          }
        })
  }, []);

  useEffect(() => {
    const result = store_rates?.find((store) => store.storeId === 10);
    setPriceInfo(result);
  }, [priceInfo]);

  const handlePaginatedData = (pageNumber) => {
    setInput({ ...input, page: pageNumber })
  }

  useEffect(() => {
    if (input.page) {
      const syntheticEvent = new Event('submit');
      handleSubmit(syntheticEvent)
    }
  }, [input.page])

  const applyFilters = () => {
    const syntheticEvent = new Event('submit');
    handleSubmit(syntheticEvent)
  }

  const handleValuationFilter = (event, category) => {
    setValuation((prevState) => {
      if (event.target.checked) {
        const filters = JSON.parse(localStorage.getItem('product-filters'))
        var filterKey, filterSeperator;
        for (const key in filters) {
          if (filters[key].values) {
            for (const value of filters[key].values) {
              if (value.label === category.label) {
                filterKey = filters[key].key;
                filterSeperator = filters[key].separator
              }
            }
          }
        }
        return {
          ...prevState,
          values: [...prevState.values, category.value],
          key: filterKey,
          separator: filterSeperator
        };
      } else {
        return {
          ...prevState,
          values: prevState.values.filter((value) => value !== category.value),
        };
      }
    });
  }

  const handleAttributeFilter = (event, elm) => {
    setAttrValues((prevState) => {
      if (event.target.checked) {
        const filters = JSON.parse(localStorage.getItem('product-filters'))
        var filterKey, filterSeperator;
        for (const key in filters) {
          if (filters[key].values) {
            for (const value of filters[key].values) {
              if (value.values) {
                for (const nestedValue of value.values) {
                  if (nestedValue.label === elm.label) {
                    filterKey = filters[key].key;
                    filterSeperator = filters[key].separator
                  }
                }
              }
            }
          }
        }
        return {
          ...prevState,
          values: [...prevState.values, elm.value],
          key: filterKey,
          separator: filterSeperator
        };
      } else {
        return {
          ...prevState,
          values: prevState.values.filter((value) => value !== elm.value),
        };
      }
    });
  }

  const handleSubmit = (value) => {
    if (
      input.keyword.startsWith("http://") ||
      input.keyword.startsWith("https://")
    ) {
      detailsPage(input.keyword);
    }
    else if (input.keyword.trim() === "" || value === "") {
      setInput({ ...input, errors: "Please insert keyword or paste link!" })
    }
    else {
      setLoading(true);
      const response = fetchProducts(input, valuation, attrValues, priceRange);
      response
        .then((res) => {
          if (res?.data?.filters?.configurator) {
            localStorage.setItem('product-filters', JSON.stringify(res.data.filters.configurator))
          }
          setPriceInfo(
            store_rates.find((item) => item.storeId === input.storeId)
          );
          setProducts(res?.data);
          if (input.keyword && input.storeId) {
            let baseQuery = `q=${input.keyword}&storeId=${input.storeId}`
            history.replace({ search: `${baseQuery}` });
            if (input.page) {
              baseQuery += `&page=${input.page}`
              history.replace({ search: `${baseQuery}` });
            }
            if (priceRange?.key) {
              baseQuery += `&${priceRange.key}=${priceRange.min}${priceRange.separator}${priceRange.max}`
              history.replace({ search: `${baseQuery}` });
            }
            if (valuation?.values?.length > 0) {
              let values = valuation.values.join(",").replaceAll(",", valuation.separator)
              baseQuery += `&${valuation.key}=${values}`
              history.replace({ search: `${baseQuery}` });
            }
            if (attrValues?.values?.length > 0) {
              let values = attrValues.values.join(",").replaceAll(",", attrValues.separator)
              baseQuery += `&${attrValues.key}=${values}`;
              history.replace({ search: `${baseQuery}` });
            }
            if (input.sort.sortOrder && input.sort.sortType) {
              baseQuery += `&sortType=${input.sort.sortType}&sortOrder=${input.sort.sortOrder}`;
              history.replace({ search: `${baseQuery}` });
            }
          }
        })
        .catch((err) => {
          setLoading(false);
        }).finally(x=>{
          setLoading(false);
          setFiltersList([]);
        }
      )
    }
  };

  const clearFilters = () => {
    setPriceRange({
      key: "",
      separator: "",
      min: null,
      max: null
    })
    setAttrValues({
      key: "",
      values: [],
      separator: ""
    })
    setValuation({
      key: "",
      values: [],
      separator: ""
    })
    setLoading(false)
  }

  useEffect(() => {
    const imageSearchResponse = fetchProducts(input);
    imageSearchResponse.then((res) => {
    }).catch(({ response }) => {
      if (response?.data) {
        messageApi.open({
          type: 'error',
          content: response?.data?.message,
          style: {marginTop: '50px'}
        })
      }
    })
  }, [input.imageUuid])

  const sortItems = products?.filters?.sorter?.values?.map((item, index) => {
    return {
      key: index,
      label: item.title
    }
  });

  const handleSortMenuClick = (option) => {
    setVisible(false);
    setSortLabel(option.label)
    const result = products?.filters?.sorter?.values.find((item) => item.title === option.label)
    const { key, value } = result
    setInput(prevInput => ({
      ...prevInput,
      sort: {
        ...prevInput.sort,
        sortOrder: value.toLowerCase(),
        sortType: key.toLowerCase()
      }
    }));
  };

  useEffect(() => {
    if (input.sort.sortOrder && input.sort.sortType) {
      const syntheticEvent = new Event('submit');
      handleSubmit(syntheticEvent)
    }
  }, [input.sort])

  const menu = (
    <Menu >
      {sortItems?.map(option => (
        <Menu.Item onClick={() => handleSortMenuClick(option)} key={option.key}>{option.label}</Menu.Item>
      ))}
    </Menu>
  );

  const customContent = (
    <div style={{ background: "#FFF", overflow: "auto", minHeight: "200px", maxHeight: "600px", }}>
      <div style={{ width: "100%", padding: "0px 20px 0px 20px", position: 'relative' }}>
        <p className="product-list-filter-header">Filter By</p>
        <Collapse expandIconPosition="right" items={filtersList} expandIcon={({ isActive }) => isActive ? <FilterShrink /> : <FilterExpand />} />
        <div style={{ paddingTop: '20px',position: 'sticky',bottom: 0 ,background: "#FFF" }}>
          <Button className="product-list-filter-cta" onClick={applyFilters}>Apply</Button>
        </div>
        <p style={{ cursor: 'pointer', paddingTop: '12px', paddingBottom: '24px', textAlign: 'center' }} onClick={clearFilters} className="product-list-filter-clear">Clear All</p>
      </div>
    </div>
  )

  return (
    <Layout
      style={{
        position: "relative",
        margin: "4px 16px",
        padding: 24,
        minHeight: "screen",
        background: "white",
        borderRadius: "8px",
        width: "95%",
        opacity: loading ? 0.5 : 1,
        boxShadow: '0px 4px 4px 0px rgba(29, 49, 72, 0.08)'
      }}
    >
      {contextHolder}
      <Row className="product-list-filters" gutter={[16,16]} >
        <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={6} style={{ display: "flex",gap: '16px' }} className="product-list-filters-first">
          {/*<Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6} style={{ display: "flex" }}>*/}
            <Tooltip placement="bottomRight" title={customContent} arrow={false}  trigger='click' >
              <Button
                style={{
                  width: "100%",
                  border: "1px solid #E5E7EB",
                  boxShadow: "none",
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Filters />
                <span className="product-list-filter-text">Filters</span>
              </Button>
            </Tooltip>
            <Dropdown
              visible={visible}
              overlay={menu}
              onVisibleChange={visible => setVisible(visible)}
              trigger={['click']}
            >
              <Button
                style={{
                  width: "100%",
                  border: "1px solid #E5E7EB",
                  boxShadow: "none",
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Sort />
                {sortLabel ? <span className="product-list-filter-text">{sortLabel}</span> : <span className="product-list-filter-text">Sort</span>}
              </Button>
            </Dropdown>
          </Col >
          <FormBlock
            handleStoreChange={handleStoreChange}
            stores={stores}
            Option={Option}
            handleSubmit={handleSubmit}
            input={input}
            setInput={setInput}
            // uploadImage={uploadImage}
          />
          <Col xs={24} sm={24} md={24} lg={24} xl={4} xxl={4} style={{ display: "flex" }} className="product-list-filters-last">
              <Tooltip placement="top" title={"upcoming"} arrow={false}>
                <Button
                  style={{
                    width: "100%",
                    border: "1px solid #E5E7EB",
                    boxShadow: "none",
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <ProductListDownload />
                  <span className="product-list-filter-text">Multiple Import</span>
                </Button>
              </Tooltip>
            </Col>
      </Row>

      {input.errors && (<Row style={{ display: "flex", justifyContent: "center", color: "red" }}>
        {input.errors}
      </Row>)}

      <ListContainer products={products} priceInfo={priceInfo} detailsPage={detailsPage} loading={loading} />
      {products?.data?.length > 0 && (
        <Row
          style={{
            paddingBottom: "40px",
            paddingTop: "38px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Pagination
            onChange={handlePaginatedData}
            itemRender={customArrow}
            defaultCurrent={1}
            total={products?.total} />
        </Row>
      )}
    </Layout>
  );
}

export default Products;
